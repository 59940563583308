<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30"
      :style="{
        backgroundImage: `url(${backgroundImage})`
      }"
    >
      <!-- begin:: Content -->
      <h1
        class="font-size-sm-100 font-weight-boldest text-dark-75 mt-15"
        style="font-size: 150px;"
      >
        401
      </h1>
      <p class="font-size-h3 font-weight-light">
        No tienes permisos para ver este contenido
      </p>
      <v-row>
        <v-col cols="12" md="4" sm="6" xs="12">
          <v-btn
            class="ma-2 btn-bg-light white--text font-weight-medium text-capitalize"
            small
            depressed
            color="light-blue-502"
            :to="{name: 'login'}"
          >
            Ir a la página de inicio de sesión
          </v-btn>
        </v-col>
      </v-row>
      <!-- end:: Content -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Unahothorized",
  mounted() {},
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/error/bg1.jpg";
    }
  }
};
</script>
